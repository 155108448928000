body {
  margin: 0;
  padding: 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(47deg, rgba(255, 112, 178, 0.3) 0%, rgba(215, 149, 251, 0.3) 100%);
  position: relative;
}
/* body::after {
  position: absolute;
  content: '';
  background: radial-gradient(circle, rgba(215, 149, 251, 0.4) 0%, rgba(252,70,107,0) 60%);
  width: 100%;
  height: 100%;
  top: 0;
  left: -50%;
  z-index: -1;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
