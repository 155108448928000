body {
  padding: 0;
}

#root {
  height: 100vh;
}

.App {
  text-align: center;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.App-logo {
  width: 50%;
  max-width: 1000px;
  pointer-events: none;
}
.pieslicer {
  max-width: 150px;
}


.App-header {
  /* background-color: #eee; */
  height: 100%;
  align-items: center;
  justify-content: center;
  flex: 1;
  font-size: calc(10px + 2vmin);
  color: rgb(87, 87, 87);
}
.App-header div {
  flex: 1;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}
.row-column {
  display: flex;
  flex-direction: row;
  gap: 40px;
}
@media (max-width: 600px) {
  .App-header div {
    flex: auto;
  }
  .row-column {
    flex-direction: column;
    gap: 50px;
  }
}

.content {
  max-width: 800px;
  margin-bottom: 20px;
}

.contact a, .App-link {
  color: #FF338E;
  text-decoration: none;
}
.contact {
  margin-top: 20px;
  font-size: 0.7em;
}

.privacy {
  font-size: 12px;
}

.hidden {
  opacity: 0;
}

.hotText {
  position: relative;
}
a.button {
  background: #ff70b2;
  color: white;
  border-radius: 6px;
  padding: 4px 8px;
  text-decoration: none;
  font-size: 0.8em;
  font-weight: bold;
  margin-top: 0.7em;
  position: relative;
}
img.hoverHearts {
  position: absolute;
  width: calc(100% + 1.2em);
  top: 0.1em;
  left: -0.6em;
  transition: 500ms opacity ease;
  opacity: 0;
}
a.button:hover img.hoverHearts,
.hotText:hover img.hoverHearts {
  opacity: 1;
}

